<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="18" :offset="3">
        <div class="">
          <el-container>
            <el-header>
              <el-menu
                :default-active="activeIndex2"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#e26538"
                text-color="#fff"
                active-text-color="#ffd04b" style="height: 72px;">
                <el-row :gutter="20" style="margin-bottom:0px">
                  <el-col :span="4"><div style="padding: 2px;">
                      <el-image   :src="menupic"    style="width: 400px; height: 70px"  ></el-image></div></el-col>
                  <el-col :span="8" :offset="6"><div >


                    <el-row :gutter="20" style="margin-bottom:0px">
  <el-col :span="6"><div ><el-menu-item index="1">首页</el-menu-item></div></el-col>
  <el-col :span="6"><div ><el-menu-item index="2">课程安排</el-menu-item></div></el-col>
  <el-col :span="6"><div ><el-menu-item index="3">通知公告</el-menu-item></div></el-col>

</el-row>



                      
                      
                      
                    </div></el-col>
                </el-row>
              </el-menu>
            </el-header>
            <el-main>
              <el-row>
                <el-col :span="24">
                  <div class="">
                    <el-row :gutter="20" class="justify-between">
                      <el-col :span="15">
                        <div class=" ">
                          <el-carousel height="400px">
                            <el-carousel-item
                              v-for="item in picurl"
                              :key="item"
                            >
                              <el-image :src="item.url" :fit="fit"></el-image>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </el-col>
                      <el-col :span="9">
                        <div><span style="font-size: 20px">简介</span></div>
                        <div class="bg-blue light">
                          <p
                            align="left"
                            style="
                              text-indent: 2em;
                              font-size: 14px;
                              background: #fff;
                            "
                          >
                            玉泉区青少年是玉泉区唯一的一所公办性质的青少年校外教育活动场所。历史悠久，始建于1963年。在区委、区政府、区教育局的高度重视和大力投入下，2018年迁至综合体新址。玉泉区青少年宫又称（小骏马）宫，以"小骏马"精神为核心，以"成就儿童青少年梦想的乐园"为目标，以文化艺术科技等社团为品牌，不断推进素质教育。青少年宫具有综合性强、公益性强、科技创新性强和突出未成年人思想道德教育"三强、一突出"的特点，为广大中小学幼儿园学生课余时间发展兴趣特长、陶冶情操、启迪心灵、锻炼体能、施展才华提供了一流的服务和广阔的舞台。青少年宫设有五大中心30多种课程：包括器乐、声乐、舞蹈、美术四大项目，还设有体育、科技、研学社会实践等诸多课程，整体打造集德育、智育、体育、美育、劳动科技教育为一体的综合立体式、多元创新型青少年活动场所，争创自治区校外教育知名品牌。
                          </p>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-divider content-position="left" class="divider1">
                    <span class="text1">活动、通知</span>
                  </el-divider>
                  <div class="grid-content">
                    <el-row :gutter="20">
                      <el-col :span="8"><div class="grid-content " >
                        <div class="text3956 text-xxl">最新通知</div>
                          <el-card class="box-card" style="height: 100%">
  
                  <div v-for="o in 6" :key="o" class="text item">
                  
                  </div>
                </el-card>
                        </div></el-col>
                      <el-col :span="16" ><div class="grid-content">
                          <div class="text3956 text-xxl">活动开办</div>
                          <el-carousel :interval="4000" type="card" height="300px">
    <el-carousel-item v-for="item in playurls" :key="item">
      <h4 class="medium">{{ item.name }}</h4>
      <el-image :src="item.url" :fit="fit"></el-image>
    </el-carousel-item>
  </el-carousel>
                        </div></el-col>
                    </el-row>

                    <el-divider content-position="left" class="divider1">
                      <span class="text1">课程、校区位置</span>
                    </el-divider>
                    <el-row :gutter="20">
                      <el-col :span="9">
                        <div class="text3956 text-xxl">开办课程</div>
                        <div class="grid-content flex1774 flex flex-wrap">
                          <div
                            v-for="item in classcpu"
                            :key="item.id"
                            class="flex6786 light flex flex-wrap justify-center"
                            :class="item.bgcolo"
                            style="
                              align-content: center;
                              width: 60px;
                              height: 60px;
                              min-height: unset;
                            "
                          >
                            <div>{{ item.name }}</div>
                          </div>
                        </div>
                      </el-col>

                      <el-col :span="15">
                        <div class="text3956 text-xxl">校区位置</div>
                        <div class="grid-content">
                          <iframe
                            class="iframe"
                            width="100%"
                            height="500px"
                            frameborder="0"
                            scrolling="no"
                            hspace="-100"
                            vspace="-150"
                            src="https://restapi.amap.com/v3/staticmap?zoom=17&size=1224*800&labels=玉泉区青少年宫,2,0,16,0xFFFFFF,0x008000:111.665782,40.801759&paths=5,0x0000ff,,0x0000ff,:111.665575,40.801936;111.665945,40.802017;111.666208,40.801473;111.665816,40.801331&key=cd377e9401c75c173421877fec6bea78"
                          ></iframe>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </el-main>
          </el-container>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "../colorui/animation.css";
import "../colorui/main.css";
import "../colorui/icon.css";
export default {
  data() {
    return {
      fit:"",
      activeIndex: "1",
      activeIndex2: "1",
      menupic: require("@/assets/img/toplog.png"),
      picurl: [
        { url: require("@/assets/img/wg.jpg") },
        { url: require("@/assets/img/js1.jpg") },
        { url: require("@/assets/img/js2.jpg") },
        { url: require("@/assets/img/js3.jpg") },
      ],
      classcpu: [
        { id: 1, name: "马头琴   ", bgcolo: "bg-cyan" },
        { id: 1, name: "小提琴   ", bgcolo: "bg-red" },
        { id: 2, name: "钢琴     ", bgcolo: "bg-olive" },
        { id: 3, name: "古筝     ", bgcolo: "bg-yellow" },
        { id: 4, name: "吉他     ", bgcolo: "bg-orange" },
        { id: 5, name: "尤克里里 ", bgcolo: "bg-green" },
        { id: 6, name: "竹笛     ", bgcolo: "bg-cyan" },
        { id: 7, name: "葫芦丝   ", bgcolo: "bg-blue" },
        { id: 8, name: "非洲鼓   ", bgcolo: "bg-purple" },
        { id: 9, name: "琵琶     ", bgcolo: "bg-mauve" },
        { id: 10, name: "爵士鼓   ", bgcolo: "bg-pink" },
        { id: 11, name: "民族舞   ", bgcolo: "bg-brown" },
        { id: 12, name: "街舞     ", bgcolo: "bg-grey" },
        { id: 13, name: "啦啦操   ", bgcolo: "bg-mauve" },
        { id: 14, name: "武术     ", bgcolo: "bg-gray" },
        { id: 15, name: "声乐     ", bgcolo: "bg-red" },
        { id: 16, name: "创意美术 ", bgcolo: "bg-pink" },
        { id: 17, name: "传统绘画 ", bgcolo: "bg-purple" },
        { id: 18, name: "儿童画   ", bgcolo: "bg-yellow" },
        { id: 19, name: "书法     ", bgcolo: "bg-mauve" },
        { id: 20, name: "素描     ", bgcolo: "bg-red" },
        { id: 21, name: "机器人   ", bgcolo: "bg-brown" },
        { id: 22, name: "魔方     ", bgcolo: "bg-pink" },
        { id: 23, name: "跆拳道   ", bgcolo: "bg-red" },
      ],
      playurls: [
          {name:"群舞《傣家竹韵》在呼市第四届中小学美育节荣获集体节目一等奖",url:require("@/assets/play/14.jpg") },
          {name:"玉泉区青少年庆祝新中国成立70周年精品课堂展示活动",url:require("@/assets/play/22.jpg") },
          {name:"2019年玉泉区民俗文化旅游节艺术展演声乐演出",url:require("@/assets/play/12.jpg") },
          {name:"行走的少年宫”课程进校园机器人公益课",url:require("@/assets/play/28.jpg") },
          {name:"爱在青城·让心向阳——走进玉泉、走进学校、走进社区”心理健康专题公益讲座",url:require("@/assets/play/24.jpg") }

        ]
    };
  },
  mounted() {
    // 在组件挂载后手动触发脚本加载
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
 
.el-footer {
  background-color: #e26538;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  color: #333;
  text-align: center;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 360px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 460px;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.divider1 {
  border-width: 10px !important;
  background-color: #ef5767 !important;
}

.text1 {
  background-color: #fff;
  color: #000;
}
.text3956 {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.flex1774 {
  width: 100%;
  height: 100%;
}
.flex6786 {
  width: 15%;
  width: calc(15% - 22px);
  width: 100px;
  width: 100px;
  height: 100px;
  margin-top: 11px;
  margin-bottom: 11px;
  margin-left: 11px;
  margin-right: 11px;
}
</style>